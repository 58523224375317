import React from "react"
import styles from "./hero.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import TrimmedContainer from "./trimmed-container";
import Img from "gatsby-image"

const AboutHero = () => {
    const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "QuintinOrsmondDev.png" }) {
          childImageSharp {
            fluid(maxWidth: 1180, traceSVG: {  color: "#035c96" }) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `);

    return (
        <div className={styles.aboutwall}>
            <TrimmedContainer>
                <section className={styles.container}>
                    <div className={styles.lead}>
                        <h1 className={styles.myName}>Quintin Orsmond</h1>

                        <h2 className={styles.myPurpose}>Enthusiastic and professional full-stack developer.</h2>
                    </div>
                    <div className={styles.profileImgContainer}>
                        <Img alt="Quintin Orsmond" fluid={data.placeholderImage.childImageSharp.fluid} />
                    </div>
                </section>
            </TrimmedContainer>
            <div className={styles.fullpageDivider}></div>
        </div>
    )
}

export default AboutHero;