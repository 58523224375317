import React from "react"
import styles from "./about.module.scss"
import TrimmedContainer from "./trimmed-container";
import { Link } from "gatsby"

const About = () => {
    return (
        <TrimmedContainer>
            <div className={styles.container}>

                <blockquote className={styles.blockquoteIntro}>
                    Hi, I am a full-stack developer with more than ten years of experience. My passion for software development has grown into an unstoppable force that keeps propelling me forward into the everchanging world of creating mission-critical products for my clients.
                </blockquote>

                <p>
                    As a self-taught programmer, I regularly make time to supplement my knowledge with relevant sources in order to be the best developer I can possibly be. I have been fortunate to work alongside very bright minds from which I have learned invaluable skills. That is why I love to share what I have learned with others.
                </p>

                <p>
                    In my journey as a developer, I fused my love for coding with my other hobby that is picture framing. A passion project quickly took shape and a small venture has grown from it. This project has been indispensable in teaching me the true power of the software development life cycle. I continue to learn from it and apply my improved skills to the projects I do for my employers and clients.
                </p>


                <p>
                    Thank you for taking the time to look at my profile. Check out <Link to="/projects/" style={{color: `#00305a`, textDecoration: 'underline'}}>what I have built</Link> and let's have a chat if you want to work together.
                </p>

            </div>
        </TrimmedContainer>
    );
};

export default About;