import React from "react"
import styles from "./skills.module.scss"
import TrimmedContainer from "./trimmed-container";
import CSharp from "../assets/svgs/c-sharp.svg"
import JScript from "../assets/svgs/javascript.inline.svg"
import TScript from "../assets/svgs/typescript.inline.svg"
import HTML5 from "../assets/svgs/html5.inline.svg"
import CSS3 from "../assets/svgs/css3.inline.svg"
import SASS from "../assets/svgs/sass.inline.svg"
import SQL from "../assets/svgs/SQL-Databases.svg"
import Dart from "../assets/svgs/dart.inline.svg"
import Bash from "../assets/svgs/bash.inline.svg"
import StencilJS from "../assets/svgs/stencil.svg";
import Angular from "../assets/svgs/angular-icon.svg";
import ReactLogo from "../assets/svgs/reactlogo.svg";
import Webcomponents from "../assets/svgs/webcomponents.svg";
import Ionic from "../assets/svgs/ionic.svg";
import Jest from "../assets/svgs/jest.svg";
import Puppeteer from "../assets/svgs/puppeteer.svg";
import Jenkins from "../assets/svgs/jenkins.svg";
import Selenium from "../assets/svgs/selenium.svg";
import Lambdatest from "../assets/svgs/lamddalogo.svg";
import Flutter from "../assets/svgs/flutter.svg";
import ASPCore from "../assets/svgs/NET_Core_Logo.svg";
import NodeJSLogo from "../assets/svgs/Nodejslogo.svg";
import Nginx from "../assets/svgs/icon-NGINX-OSS-2.svg";
import GraphQL from "../assets/svgs/graphql.svg";
import GatsbyLogo from "../assets/svgs/gatsby.svg";
import MariaDB from "../assets/svgs/mariadb-icon.svg";
import SQLServer from "../assets/svgs/mssql.svg";
import GitIco from "../assets/svgs/git-icon.svg";
import Jira from "../assets/svgs/Jiralogo.svg";
import PWA from "../assets/svgs/pwa.svg";
import RxJS from "../assets/svgs/reactivex.svg";
import VueLogo from "../assets/svgs/vue.svg";
import Cucumber from "../assets/svgs/cucumber.svg";
import Chai from "../assets/svgs/chai.svg";
import xunit from "../assets/svgs/xunit.svg";
import specflow from "../assets/svgs/specflow.svg";
import rnative from "../assets/svgs/react-native.svg"

const Skills = () => {


    return (
        <TrimmedContainer>
            <div>
                <h2 className={styles.heading}>My Current Skills</h2>

                <div>
                    <h3>Languages</h3>
                    <p>I started to program back in 2003 and picked up new languages as I progressed throughout my career. <br /> I feel most at home with the C-family of programming languages.</p>

                    <div className={styles.langlogos}>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="300"
                            data-sal-easing="ease">
                            <img src={CSharp} loading="lazy" alt="CSharp" />
                            <figcaption>C#</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="100"
                            data-sal-easing="ease">
                            <JScript />
                            <figcaption>JavaScript</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                            <TScript />
                            <figcaption>TypeScript</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="400"
                            data-sal-easing="ease">
                            <Dart />
                            <figcaption>Dart</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="450"
                            data-sal-easing="ease">
                            <HTML5 style={{ height: `105px` }} />
                            <figcaption>HTML 5</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="500"
                            data-sal-easing="ease">
                            <CSS3 style={{ height: `105px` }} />
                            <figcaption>CSS 3</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="550"
                            data-sal-easing="ease">
                            <SASS />
                            <figcaption>Sass</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="600"
                            data-sal-easing="ease">
                            <img src={SQL} loading="lazy" alt="SQL" />
                            <figcaption>T-SQL</figcaption>
                        </figure>

                    </div>




                    <h3>Frontend</h3>
                    <p>
                        I love all the goodness that frameworks such as Angular, React and Vue brings to the table, yet, I am a strong believer in understanding the fundamentals and patterns that shape these frameworks.
                    <br />
                        Lately, I have started using Web Components and believe they hold a bright future in the frontend.
                    </p>


                    <div className={styles.techlogos}>

                        {/* <figure
                            data-sal="zoom-in"
                            data-sal-delay="0"
                            data-sal-easing="ease">
                            <img src={PWA} style={{ width: `130px` }} loading="lazy" alt="PWA"/>
                            <figcaption>Progressive web apps</figcaption>
                        </figure> */}

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                            <img src={Webcomponents} loading="lazy" alt="Webcomponents" />
                            <figcaption>Web Components</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="300"
                            data-sal-easing="ease">
                            <img src={StencilJS} loading="lazy" alt="StencilJS" />
                            <figcaption>StencilJS</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="400"
                            data-sal-easing="ease">
                            <img src={RxJS} loading="lazy" alt="RxJS" />
                            <figcaption>RxJS</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="450"
                            data-sal-easing="ease">
                            <img src={Angular} loading="lazy" alt="Angular" />
                            <figcaption>Angular</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="500"
                            data-sal-easing="ease">
                            <img src={ReactLogo} loading="lazy" alt="ReactLogo" />
                            <figcaption>React</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="550"
                            data-sal-easing="ease">
                            <img src={VueLogo} loading="lazy" alt="ReactLogo" />
                            <figcaption>Vue</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="600"
                            data-sal-easing="ease">
                            <img src={GatsbyLogo} loading="lazy" alt="GatsbyLogo" />
                            <figcaption>Gatsby</figcaption>
                        </figure>


                    </div>


                    <p>I have build production mobile apps with these frameworks</p>

                    <div className={styles.techlogos}>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="100"
                            data-sal-easing="ease">
                            <img src={Flutter} loading="lazy" alt="Flutter" />
                            <figcaption>Flutter</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                            <img src={rnative} loading="lazy" alt="React Native" />
                            <figcaption>React Native</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="300"
                            data-sal-easing="ease">
                            <img src={Ionic} loading="lazy" alt="Ionic" />
                            <figcaption>Ionic</figcaption>
                        </figure>
                    </div>


                    <p>I have experience using these frontend testing tools.</p>

                    <div className={styles.techlogos}>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="100"
                            data-sal-easing="ease">
                            <img src={Jest} loading="lazy" alt="Jest" />
                            <figcaption>Jest</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                            <img src={Chai} loading="lazy" alt="Chai" />
                            <figcaption>Chai</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                            <img src={Puppeteer} loading="lazy" alt="Puppeteer" />
                            <figcaption>Puppeteer</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="300"
                            data-sal-easing="ease">
                            <img src={Cucumber} loading="lazy" alt="Cucumber" />
                            <figcaption>CucumberJS</figcaption>
                        </figure>
                    </div>


                    <h3>Backend</h3>
                    <p>
                        I have mainly used the Microsoft stack on Windows and Linux Servers for the backend. I have also used Nodejs for building small microservices and larger backends.
                    </p>

                    <div className={styles.techlogos}>

                        <figure className={styles.netcorelogo}
                            data-sal="zoom-in"
                            data-sal-delay="100"
                            data-sal-easing="ease">
                            <img src={ASPCore} loading="lazy" alt="ASPCore" />
                            <figcaption style={{ whiteSpace: `initial` }}>
                                <div style={{ whiteSpace: `nowrap` }}>ASP.NET Core, </div>
                                <div style={{ whiteSpace: `nowrap` }}>EF Core</div>
                            </figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                            <img src={Nginx} loading="lazy" alt="Nginx" />
                            <figcaption>NGINX</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="300"
                            data-sal-easing="ease">
                            <img src={NodeJSLogo} loading="lazy" alt="NodeJSLogo" />
                            <figcaption>NodeJS</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="400"
                            data-sal-easing="ease">
                            <img src={GraphQL} loading="lazy" alt="GraphQL" />
                            <figcaption>GraphQL</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="500"
                            data-sal-easing="ease">
                            <img src={MariaDB} loading="lazy" alt="MariaDB" />
                            <figcaption>MariaDB</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="600"
                            data-sal-easing="ease">
                            <img src={SQLServer} loading="lazy" alt="SQLServer" />
                            <figcaption>MS SQL Server</figcaption>
                        </figure>


                    </div>

                    <p>These are the testing tools I regularly use when developing for the backend.</p>

                    <div className={styles.techlogos}>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="100"
                            data-sal-easing="ease">
                            <img src={xunit} loading="lazy" alt="xUnit" />
                            <figcaption>xUnit.net</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                            <img src={specflow} loading="lazy" alt="specflow" />
                            <figcaption>Specflow</figcaption>
                        </figure>

                    </div>




                    <h3>Other</h3>
                    <p>
                        I also have the following experience.
                    </p>

                    <div className={styles.techlogos}>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="100"
                            data-sal-easing="ease">
                            <img src={GitIco} loading="lazy" alt="GIT" />
                            <figcaption>git</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                            <img src={Jenkins} loading="lazy" alt="Jenkins" />
                            <figcaption>Jenkins</figcaption>
                        </figure>

                        <figure
                            data-sal="zoom-in"
                            data-sal-delay="300"
                            data-sal-easing="ease">
                            <img src={Jira} loading="lazy" alt="Jira" />
                            <figcaption>Jira</figcaption>
                        </figure>



                    </div>

                </div>
            </div>
        </TrimmedContainer >
    );
};

export default Skills;