import React from "react"
import Layout from "../components/layout"
import AboutHero from "../components/aboutHero"
import About from "../components/about"
import Skills from "../components/skills"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Quintin Orsmond" />
    <AboutHero></AboutHero>
    <About></About>
    <Skills></Skills>
  </Layout>
)

export default IndexPage
